<template>
  <main>
    <CContainer>
      <CCard>
        <CCardHeader>
          <h5 class="mb-0">{{ $t('Navigation.Message/MailContent') }}</h5>
        </CCardHeader>
        <CCardBody>
          <CTabs fade addTabsWrapperClasses="tab-info">
            <CTab title="系統郵件內容" active>
              <CTabs variant="pills" :vertical="{ navs: 'col-lg-2 col-md-3 col-sm-12 col-12 mb-4', content: 'col-lg-10 col-md-9 col-sm-12 col-12' }">
                <template v-for="(Content, Index) in Data.Content">
                  <CTab :title="$t(`Message/MailContent.Type.${Index}`)" :key="Index">
                    <h5>{{ $t(`Message/MailContent.Type.${Index}`) }}</h5>
                    <hr>
                    <div class="form-group">
                      <label class="d-block">啟用通知</label>
                      <CSwitch color="success" :checked.sync="Content.NotifyEnabled" @update:checked="Update(Index)" />
                    </div>
                    <CSelect label="郵件模板" :options="TemplateList" v-model="Content.Template" :value.sync="Content.Template" :placeholder="$t('Global.PleaseSelect')" :description="$t('Message/MailTemplate.DefaultTemplateNotify')" @update:value="Update(Index)" />
                    <div v-if="Index === 'LogisticsStatus'" class="form-group">
                      <label class="d-block mb-3">需寄送通知的配送狀態</label>
                      <div>
                        <label v-for="Item in LogisticsStatusOptions" :key="Item.value" :for="Item.value + '-Enable'"
                               class="mr-2">
                          <input type="checkbox" :id="Item.value + '-Enable'" name="EnableStatus" :value="Item.value"
                                 v-model="Content.EnableStatus" @change="CheckEnableStatus(Index)"><span class="ml-2">{{ Item.label }}</span>
                        </label>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="d-block">{{ $t('Message/MailTemplate.TitleLabel') }}</label>
                      <div class="border-secondary py-2 px-3 rounded">{{ Content.Title }}</div>
                    </div>
                    <div class="form-group">
                      <label class="d-block">{{ $t('Message/MailTemplate.ContentLabel') }}</label>
                      <div class="border-secondary p-3 rounded" v-html="Content.Content"></div>
                    </div>
                    <CButton color="success" @click="Edit(Index)" class="mr-2">{{ $t('Global.Edit') + $t('Message/MailTemplate.ContentLabel') }}</CButton>
                    <CButton color="info" @click="SendTestMail(Index)">{{ $t('Message/MailTemplate.TestMail') }}</CButton>
                  </CTab>
                </template>
              </CTabs>
            </CTab>
<!--            <CTab title="自訂郵件內容">-->
<!--            </CTab>-->
          </CTabs>
          <CElementCover v-if="(Loading === true)" :opacity="0.75">
            <CSpinner color="success"/>
          </CElementCover>
        </CCardBody>
      </CCard>
    </CContainer>
    <CModal
      title="edit"
      :show.sync="ShowModal"
      size="xl"
      @update:show="HandleClose"
    >
      <template v-if="ShowModal">
        <h6 class="font-weight-bold mb-2">{{ $t('Message/MailTemplate.TitleLabel') }}</h6>
        <CInput v-model="Data.Content[EditingCategory].Title"/>
        <h6 class="font-weight-bold mb-2">{{ $t('Message/MailTemplate.ContentLabel') }}</h6>
        <div class="mb-2">
          <CButton color="info" variant="outline" class="mr-2" size="sm" @click="InsertString(InsertButtons[str])" v-for="(str, key) in Object.keys(InsertButtons)" :key="key">{{ $t(`Message/MailTemplate.TableLabels.${str}`) }}</CButton>
        </div>
        <div class="mb-3">
          <Editor ref="TinyMCE_Editor" api-key="no-api-key" tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.10.9/tinymce.min.js" :init="TinyMCE" v-model="Data.Content[EditingCategory].Content" />
        </div>
        <CAlert color="info">
          <h6 class="font-weight-bold mb-2"><i class="far fa-bell mr-1" />{{ $t('Message/MailTemplate.Params') }}</h6>
          <p class="mb-0">
            範例：在信件輸入「<b>{:LOGO:}</b>」或以上方的快速插入按鈕按下「<b>Logo圖片</b>」後，將會自動套入郵件內容。
          </p>
        </CAlert>
      </template>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h4>{{ $t('Global.Edit') }}{{ $t('Navigation.Message/MailContent') }}</h4>
          <CButtonClose @click="ShowModal = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <footer class="modal-footer border-top-0">
          <CButton @click="ShowModal = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton @click="Update(EditingCategory)" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
  "meta": {
    "label": "郵件內容"
  }
}
</route>

<script>
import Editor from '@tinymce/tinymce-vue'
import TinyMCESetting from '@/plugins/tinymce'

export default {
  name: 'EditMailContent',
  layout: 'manage',
  components: {
    Editor
  },
  data () {
    return{
      TinyMCE: TinyMCESetting,
      Data: {
        Content: {}
      },
      Loading: false,
      ShowModal: false,
      EditingCategory: '',
      // MailContentList: ['Register', 'OrderCreated', 'OrderCancel', 'Unpaid', 'Paid', 'LogisticsStatus', 'NotPickUp'],
      InsertButtons: {
        'Title': '{:TITLE:}',
        'Logo': '{:LOGO:}',
        'SiteName': '{:SITENAME:}',
        'Email': '{:EMAIL:}',
        'Tel': '{:TEL:}',
        'Address': '{:ADDRESS:}',
        'Content': '{:CONTENT:}',
        'Account': '{:ACCOUNT:}',
        'BuyerName': '{:BUYERNAME:}',
        'BuyerPhone': '{:BUYERPHONE:}',
        'OrderNum': '{:ORDERNO:}',
        'LogisticsStatusText': '{:SHIPMENT_STATUS:}'
      },
      StatusOptions: [],
      TemplateList: []
    }
  },
  computed: {
    LogisticsStatusOptions() {
      return Object.keys(this.$t('Order.LogisticsStatusList')).map(status => {
        return {
          value: status,
          label: this.$t('Order.LogisticsStatusList')[status]
        }
      })
    },
  },
  mounted() {
    this.Loading = true
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
      this.Loading = false
    }).catch((err) => {
      this.$Progress.fail()
      this.Loading = false
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: 'error'
      })
    })
  },
  watch: {
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetList(),
        this.GetTemplateList()
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetList() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/emailNotify/contents',
        method: 'get',
      }).then(({data}) => {
        this.Loading = false
        this.Data.Content = data.Content
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/LoadFail') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    Edit(key) {
      this.EditingCategory = key
      this.ShowModal = true
    },
    Update(EditingCategory) {
      this.Loading = true
      let UpdateData = this.Data.Content[EditingCategory]
      return this.$store.dispatch('InnerRequest', {
        url: '/emailNotify/contents',
        method: 'post',
        data: {
          Category: EditingCategory,
          UpdateData
        }
      }).then(() => {
        this.Loading = false
        this.ShowModal = false
        this.GetList()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Global/SaveSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Loading = false
        this.ShowModal =false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.System/ModifyFail') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    HandleClose (value) {
      if (!value) {
       //
      }
    },
    InsertString(data) {
      // eslint-disable-next-line no-undef
      tinymce.activeEditor.execCommand('mceInsertContent', false, data)
    },
    GetTemplateList () {
      const Data = {
        Taxonomy: 'EmailTemplate',
        Page: 1,
        PerPage: 100
      }
      this.TemplateList = []
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/content/article/list',
          Data
        }
      }).then(({data}) => {
        this.Loading = false
        this.TemplateList = (data.Data || []).map(item => {
          return {
            label: item.Name,
            value: item.Slug
          }
        })
        if (!this.TemplateList.map(item => item.value).includes('Default')) {
          this.TemplateList.push({
            label: '預設模板',
            value: 'Default'
          })
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    CheckEnableStatus (category) {
      if (!this.Data.Content[category].EnableStatus) {
        this.$set(this.Data.Content[category], 'EnableStatus', [])
      }
      const TempEnableStatus = this.Data.Content[category].EnableStatus
      setTimeout(() => {
        if (this.Data.Content[category].EnableStatus.length === TempEnableStatus.length) {
          this.Update(category)
        }
      }, 1000)
    },
    SendTestMail (category) {
      return this.$swal({
        title: this.$t('Message/MailTemplate.TestMail'),
        text: this.$t('Message/MailTemplate.TestMailDescription'),
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonColor: '#2eb85c',
        confirmButtonText: this.$t('Global.Send'),
        cancelButtonText: this.$t('Global.Cancel'),
        showLoaderOnConfirm: true,
        preConfirm: async (email) => {
          const Info = await this.$store.dispatch('InnerRequest', {
            url: '/system/get/Info',
            method: 'post'
          }).then(({data}) => {
            return data
          })
          const Contact = await this.$store.dispatch('InnerRequest', {
            url: '/system/get/Contact',
            method: 'post'
          }).then(({data}) => {
            return data
          })
          return this.$store.dispatch('InnerRequest', {
            url: '/emailNotify/single/send',
            method: 'post',
            data: {
              Title: this.Data.Content[category].Title,
              MessageType: category,
              Recipient: {
                Email: email || this.$store.state.user.data.email,
                Name: this.$store.state.user.data.displayName
              },
              MsgComponent: {
                Title: Info.Title,
                ContactName: this.$store.state.user.data.displayName,
                SiteName: Info.Title,
                SiteDomain: Info.Domain,
                Logo: Info.Logo,
                Email: Contact.Email,
                Tel: Contact.Tel,
                Address: Contact.Address,
                Facebook: '{:FBLink:}',
                Line: '{:LineLink:}',
                Instagram: '{:IGLink:}',
                SiteMail: Contact.Email,
                SiteTel: Contact.Tel,
                SiteAddress: Contact.Address,
                Account: this.$store.state.user.data.email,
                BuyerName: this.$store.state.user.data.displayName,
                BuyerPhone: this.$store.state.user.data.phoneNumber || '[手機號碼]',
                BuyerEmail: this.$store.state.user.data.email,
                OrderNum: '[訂單編號]',
                DueDay: '[到期日]',
                LogisticsStatusText: '[訂單狀態]',
                ShipmentStoreName: '[商店名稱]',
                OfferName: '[優惠名稱]',
                OfferDescription: '[優惠描述]',
                OfferSlug: '[優惠代稱]',
                OfferStartTime: '[優惠開始時間]',
                OfferEndTime: '[優惠結束時間]'
              }
            }
          })
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Success'),
            text: this.$t('Message.Message/SendSuccess'),
            type: 'success'
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.MailDisplay {
  border: 1px solid #dddddd;
  padding: 1rem;
}
</style>
